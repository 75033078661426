import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import loadable from '@loadable/component';
import { getSrc } from 'gatsby-plugin-image';

const Grid = loadable(() => import('../components/grid'));
const Card = loadable(() => import('../components/card'));
const Content = loadable(() => import('../components/content'));

const Category = ({ data }) => {
  const { projects } = data;
  const metaData = {
    // title: title.node.frontmatter.title,
    // description: title.node.frontmatter.description
  };
  return (
    <Layout metaData={metaData}>
      <Content type="full">
        <Grid>
          {projects.edges.map((item, i) => {
            const project = item.node.frontmatter;
            return (
              <Card
                key={`key${i}`}
                title={project.title}
                bg={project.coverImage ? getSrc(project.coverImage) : null}
                url={item.node.fields.slug.slice(9, item.node.fields.slug.length - 1)}
              />
            );
          })}
        </Grid>
      </Content>
    </Layout>
  );
};

export default Category;

export const query = graphql`
  query($slug: String!) {
    projects: allMarkdownRemark(
      filter: { fields: { slug: { regex: $slug } }, frontmatter: { category: { ne: true } } }
      sort: { fields: [frontmatter___index], order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            title
            category
            coverImage {
              ...mediumImage
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;
